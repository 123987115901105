import React from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const { token, login, logout } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        checkToken();
    }, []);

    const checkToken = async () => {
        if (token) {
            navigate('/dashboard');
        } else {
            handleTwitterLogin();
        }
    }


    const handleTwitterLogin = async () => {
        try {

            const response = await axios.get('https://api.xbit.network/auth/twitter/login');

            const authUrl = response.data.authUrl;

            if (authUrl) {
                window.location.href = authUrl;
            } else {
                console.error('No auth URL received from the server');
            }
        } catch (error) {
            console.error('Twitter login failed:', error);
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 flex flex-col items-center">
            <h1 className="text-4xl font-bold mb-8">Welcome to Our App</h1>
            <p className="text-xl mb-8">Please log in with your Twitter account to continue.</p>
            <button
                onClick={handleTwitterLogin}
                className="bg-blue-400 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full text-xl transition duration-300 ease-in-out transform hover:scale-105"
            >
                Login with Twitter
            </button>
        </div>
    );
};

export default Login;