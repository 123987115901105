import React from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';

const Register = () => {

    const { token } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        
        checkToken();

    }, []);

    const checkToken = async () => {
        if (token) {
            navigate('/login');
        }
    }


    return (
        <div className="container mx-auto px-4 py-8 flex flex-col items-center">
            <h1 className="text-4xl font-bold mb-8">Register</h1>
            <p className="text-xl mb-8">- - -</p>

        </div>
    );
};

export default Register;